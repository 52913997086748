html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#username-label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: black !important;
  margin: 15px !important;
}

#password-label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: black !important;
}

.MuiAppBar-colorPrimary {
  background-color: transparent !important;
  box-shadow: none !important;
  color: "unset";
}

.MuiInputLabel-formControl {
  top: -2px !important;
  left: 2px !important;
  font-size: 18px !important;
}
